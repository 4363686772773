import React, { useState } from "react";
import CardPage from "~/components/CardPage";
import Seo from "~/components/Seo";
import EnterEmail from "~/features/marketing/forgotPassword/EnterEmail";
import EnterPassword from "~/features/marketing/forgotPassword/EnterPassword";
import authBackgroundImage from "~/images/auth-background.jpg";

type Submitted = {
  readonly emailAddress: string;
  readonly tempToken: string;
};

function ForgotPassword() {
  const [submitted, setSubmitted] = useState<Submitted | undefined>();
  const onSent = (emailAddress: string, tempToken: string) => {
    setSubmitted({
      emailAddress,
      tempToken,
    });
  };

  return (
    <CardPage backgroundImage={authBackgroundImage}>
      {submitted ? (
        <EnterPassword
          emailAddress={submitted.emailAddress}
          tempToken={submitted.tempToken}
          onCodeResent={(newCode: string) =>
            setSubmitted({
              emailAddress: submitted.emailAddress,
              tempToken: newCode,
            })
          }
        />
      ) : (
        <EnterEmail onSent={onSent} />
      )}
    </CardPage>
  );
}

export function Head() {
  return <Seo title="Forgot Password" />;
}

export default ForgotPassword;
