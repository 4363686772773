import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { navigate } from "gatsby";
import {
  FormStatusError,
  SubmitButton,
  TextFormItem,
} from "~/components/forms/index";
import CodeFormItem from "~/components/forms/CodeFormItem";
import { useApiClient, useApiMutation } from "~/hooks/apiContext";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";
import { isErrorFormStatus } from "~/components/forms/FormStatus";
import Button from "~/components/Button";
import Notification from "~/components/Notification";

const validationSchema = yup
  .object({
    code: yup.string().required(),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required(),
    confirmPassword: yup.string().when("password", {
      is: (val: string) => !!val && val.length > 0,
      then: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Password must match"),
    }),
  })
  .required();

type FormValues = SchemaFieldValues<typeof validationSchema>;

const initialValues: FormValues = {
  code: "",
  password: "",
  confirmPassword: "",
};

type EnterPasswordProps = {
  readonly emailAddress: string;
  readonly tempToken: string;
  readonly onCodeResent: (code: string) => void;
};

function EnterPassword({
  emailAddress,
  tempToken,
  onCodeResent,
}: EnterPasswordProps) {
  // Resend code
  const {
    mutateAsync: resendOtp,
    isLoading: resendLoading,
    reset: resetOtp,
    isSuccess: resendSuccess,
  } = useApiMutation((client) => client.resendOtp(emailAddress));

  // Form
  const apiClient = useApiClient();
  const formik = useFormik({
    onSubmit: useFormikStatusSubmit(async ({ code, password }: FormValues) => {
      resetOtp();
      await apiClient.resetPassword(tempToken, code, password);
      navigate("/dashboard/");
    }),
    initialValues,
    validationSchema,
  });
  const { status, setStatus, setFieldValue } = formik;

  // Event handlers
  const onResendClick = async () => {
    const verificationCode = await resendOtp({});
    onCodeResent(verificationCode);
    setStatus(undefined);
    setFieldValue("code", "");
  };

  return (
    <FormikProvider value={formik}>
      <Form className="login-form">
        <h2 className="text-center mb-2">Reset password</h2>
        <p>If you have an account, we&apos;ve sent a code to {emailAddress}</p>
        <div className="mb-2">
          <CodeFormItem
            name="code"
            label="Code"
            length={4}
            disabled={resendLoading}
          />
          <TextFormItem
            type="password"
            name="password"
            label="Password"
            autoComplete="off"
            disabled={resendLoading}
          />
          <TextFormItem
            type="password"
            name="confirmPassword"
            label="Confirm password"
            autoComplete="off"
            disabled={resendLoading}
          />
        </div>
        <FormStatusError />
        {isErrorFormStatus(status) &&
          status.error.message.toLowerCase() === "incorrect code" && (
            <div style={{ marginBottom: 20 }}>
              <Button
                type="button"
                variant="outline"
                size="small"
                colour="red"
                onClick={onResendClick}
                disabled={resendLoading}
              >
                {resendLoading ? "Resending code" : "Resend code"}
              </Button>
            </div>
          )}
        {resendSuccess && (
          <Notification type="success">Code resent</Notification>
        )}
        <SubmitButton label="Submit" disabled={resendLoading} />
      </Form>
    </FormikProvider>
  );
}

export default EnterPassword;
