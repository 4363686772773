import React from "react";
import ReactCodeInput from "@acusti/react-code-input";
import { useField, useFormikContext } from "formik";
import FormItem, { FormItemProps } from "./FormItem";

type CodeFormItemProps = Pick<FormItemProps, "label" | "name"> & {
  readonly disabled?: boolean;
  readonly length: number;
};

function CodeFormItem({ name, label, length, disabled }: CodeFormItemProps) {
  const [{ value }, , { setValue }] = useField<string>(name);
  const { isSubmitting } = useFormikContext();
  const fieldId = `${name}Field`;

  return (
    <FormItem name={name} label={label} fieldId={fieldId}>
      <ReactCodeInput
        name={name}
        type="number"
        fields={length}
        inputMode="numeric"
        disabled={disabled || isSubmitting}
        value={value}
        onChange={setValue}
      />
    </FormItem>
  );
}

export default CodeFormItem;
