import React from "react";
import { Form, Formik } from "formik";
import { Link } from "gatsby";
import * as yup from "yup";
import {
  FormStatusError,
  SubmitButton,
  TextFormItem,
} from "~/components/forms/index";
import { useApiClient } from "~/hooks/apiContext";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";

const validationSchema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

type FormValues = SchemaFieldValues<typeof validationSchema>;

const initialValues: FormValues = {
  email: "",
};

type EnterEmailProps = {
  readonly onSent: (email: string, tempToken: string) => void;
};

function EnterEmail({ onSent }: EnterEmailProps) {
  const apiClient = useApiClient();

  const onSubmit = useFormikStatusSubmit(async ({ email }: FormValues) => {
    const tempToken = await apiClient.forgotPassword(email);
    onSent(email, tempToken);
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form className="login-form">
        <h2 className="text-center mb-2">Forgot password</h2>
        <div className="mb-2">
          <TextFormItem name="email" label="Email address" autoFocus />
        </div>
        <FormStatusError />
        <SubmitButton label="Reset password" />
        <div className="below-form">
          <div className="line-item">
            Back to <Link to="/dashboard/">Sign in</Link>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default EnterEmail;
